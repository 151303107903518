<template>
  <div>
    <audits-modal
      v-if="selectedAudit"
      id="modal-audit"
      :audit="selectedAudit"
    />

    <b-card id="shipment-audits">
      <div>
        <h4 class="card-title float-left">
          Shipment audit
        </h4>
        <b-form-checkbox
          id="show-automatic"
          v-model="filters.show_automatic"
          switch
          class="float-right"
        >
          Show automatic processes
        </b-form-checkbox>
      </div>

      <b-table
        class="mt-2"
        responsive
        :fields="fields"
        :items="audits"
        :busy="loading"
        show-empty
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading...</strong>
          </div>
        </template>

        <template #cell(entity)="data">
          {{ data.item.auditable_type }}
        </template>

        <template #cell(user)="data">
          <span v-if="data.item.user">
            {{ data.item.user.name }}
          </span>
          <span v-else>Automatic process</span>
        </template>

        <template #cell(created_at)="data">
          {{ data.item.created_at | formatDate }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            v-if="data.item.event !== 'deleted'"
            v-b-modal.modal-audit
            size="sm"
            @click="selectedAudit = data.item"
          >
            Show changes
          </b-button>
        </template>

      </b-table>
      <pagination
        :event-hub="eventHub"
        :per-page="perPage"
      />
    </b-card>
  </div>
</template>
<script>
import { BButton, BCard, BFormCheckbox, BSpinner, BTable } from 'bootstrap-vue'
import AuditsModal from '@/views/shipments/modals/AuditModal.vue'
import Pagination from '@/layouts/components/Pagination/Pagination.vue'
import Vue from 'vue'

export default {
  components: {
    BFormCheckbox,
    Pagination,
    AuditsModal,
    BButton,
    BSpinner,
    BTable,
    BCard,
  },
  props: ['shipmentId'],
  data() {
    return {
      loading: false,
      page: 1,
      perPage: 30,
      eventHub: new Vue(),
      selectedAudit: {},
      audits: [],
      filters: {
        show_automatic: false,
      },
      fields: [
        'created_at',
        'entity',
        'tracking_number',
        'event',
        'user',
        'actions',
      ],
    }
  },
  watch: {
    filters: {
      handler() {
        this.$data.page = 1
        this.getAudits()
      },
      deep: true,
    },
  },
  created() {
    this.getAudits()
  },
  mounted() {
    this.eventHub.$on('pageChange', newPage => {
      this.page = newPage
      this.getAudits()
    })
  },
  methods: {
    getAudits() {
      this.loading = true
      const url = `/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipmentId}/audits`

      // Apply page
      this.$http.get(url, {
        params: {
          page: this.$data.page,
          per_page: this.$data.perPage,
          ...this.filters,
        },
      })
        .then(response => {
          this.audits = response.data.data
          this.eventHub.$emit('updateMeta', response.data.meta)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
