<template>
  <b-modal
    :id="this.$attrs.id"
    :title="title"
    size="lg"
  >
    <b-card-text class="m-2 align-center">
      <b-row>
        <b-col>
          {{ confirmation }}
        </b-col>
      </b-row>
      <b-row v-if="description">
        <b-col>
          {{ description }}
        </b-col>
      </b-row>
    </b-card-text>
    <template #modal-footer="{ close }">
      <b-container fluid>
        <b-row>
          <b-col>
            <b-button
              variant="secondary"
              class="mr-2"
              @click="close"
            >
              {{ closeButton }}
            </b-button>
          </b-col>
          <b-col class="text-right">
            <b-button
              type="button"
              variant="primary"
              @click="$emit('submit')"
            >
              {{ okButton }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-modal>
</template>

<script>
import { BButton, BCardText, BCol, BContainer, BModal, BRow } from 'bootstrap-vue'

export default {
  components: {
    BCardText,
    BContainer,
    BCol,
    BRow,
    BButton,
    BModal,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    confirmation: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    closeButton: {
      type: String,
      default: 'Cancel',
    },
    okButton: {
      type: String,
      default: 'Proceed',
    },
  },
  data() {
    return {
      isVisible: true,
    }
  },
}
</script>
