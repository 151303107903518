<template>
  <b-card v-if="shipmentStatistics.length" title="Shipment Statuses">
    <b-table
        class="mt-2"
        :fields="shipmentsStatisticsFields"
        :items="shipmentStatistics"
        show-empty
    >

      <template #cell(name)="data">
        <router-link :to="data.item.link">
          <strong :class="data.item.class">{{ data.item.name }}</strong>
        </router-link>
      </template>

      <template #cell(available)="data">
        <router-link :to="data.item.link">
          <strong
              class="text-primary"
              v-b-tooltip.hover.top="'Available'"
              :class="data.item.class"
          >
            {{ data.item.available }}
          </strong>
        </router-link>
      </template>

      <template #cell(done)="data">
        <router-link :to="data.item.link">
          <strong
              class="text-success"
              v-b-tooltip.hover.top="'Done'"
              :class="data.item.class"
          >
            {{ data.item.done }}
          </strong>
        </router-link>
      </template>

    </b-table>
  </b-card>
</template>

<script>
import {
  BCard,
  VBTooltip,
  BTable,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: ['shipmentsStatisticsFields', 'shipmentStatistics', 'shipmentId'],
}
</script>
